<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-select
            v-model="vm.incidentPrimaryCategory"
            :items="incidentPrimaryCategory"
            label="Incident Primary Category"
            v-validate="'required'"
            data-vv-as="incidentPrimaryCategory"
            name="incidentPrimaryCategory"
            :error-messages="errors.collect('incidentPrimaryCategory')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="vm.incidentSecondaryCategory"
            :items="incidentSecondaryCategory"
            label="Incident Secondary Category"
            v-validate="'required'"
            data-vv-as="incidentSecondaryCategory"
            name="incidentSecondaryCategory"
            :error-messages="errors.collect('incidentSecondaryCategory')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.incidentDeathAnticipated"
            label="If the incident is a death of a person with disability, was the death anticipated?"
            v-validate="'required'"
            data-vv-as="If the incident is a death of a person with disability, was the death anticipated"
            name="incidentDeathAnticipated"
            :error-messages="errors.collect('incidentDeathAnticipated')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Unknown" value="unknown"></v-radio>
          </v-radio-group>
        </v-col>

        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "incident-category",

  data: () => ({
    vm: [],

    incidentPrimaryCategory: [
      "Death of a person with disability",
      "Serious Injury of a person with disability",
      "Abuse of a person with disability",
      "Neglect of a person with disability",
      "Unlawful sexual acts/offences",
      "Unlawful physical contact/offences",
      "Sexual misconduct against a person with disability",
      "Unauthorised use of Restrictive Practice"
    ],
    incidentSecondaryCategory: [
      "Death of a person with disability",
      "Serious Injury of a person with disability",
      "Abuse of a person with disability",
      "Neglect of a person with disability",
      "Unlawful sexual acts/offences",
      "Unlawful physical contact/offences",
      "Sexual misconduct against a person with disability",
      "Unauthorised use of Restrictive Practice"
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentIncident == undefined ||
        this.currentIncident.incidentCategory == undefined
          ? {}
          : this.currentIncident.incidentCategory;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentIncident.incidentCategory = this.vm;
          this.$store.dispatch("setCurrentIncident", this.currentIncident);
          this.next();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },

    next() {
      this.$emit("next-step");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

